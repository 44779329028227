import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import Button from "@mui/material/Button";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "../AinaHome/NavbarTest";
import { useTranslation } from "react-i18next";
import AinaCarRentACAR from "assets/images/fleet.webp";

import footerRoutes from "footer.routes";
import Container from "@mui/material/Container";
import Whatsapp from "../AinaHome/whatsapp";

import Karve02 from "../AinaHome/karve02";
import Footer from "../AinaHome/footer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MKButton from "components/MKButton";
import axios from "axios";


// ContactDialog Component
function ContactDialog({ openDialog, handleClose }) {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: { xs: "100%", md: "350px", lg: "35k  0px" }, // Limita el ancho máximo en pantallas grandes
            width: "100%", // Asegura que ocupe el ancho completo en pantallas pequeñas
          },
        }}
      >
        <DialogContent sx={{ padding: 2 }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{ height: "100%" }}
          >
            <Grid item sx={{ width: "100%" }}>
              <MKButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => window.open("tel:+123456789", "_blank")}
              >
                Llámanos al 937 273 907
              </MKButton>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <Button
                sx={{ backgroundColor: "#075e54", color: "#ffffff" }}
                variant="contained"
                fullWidth
                onClick={() => window.open("https://wa.me/123456789", "_blank")}
              >
                ¿Hablamos por WhatsApp?
              </Button>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <MKButton
                variant="contained"
                color="info"
                fullWidth
                onClick={() => window.open("mailto:info@example.com", "_blank")}
              >
                Envíanos un e-mail reservas@ainacar.cat
              </MKButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function AinaPromo() {
  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const [open, setOpen] = useState(false);

  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    console.log(nuevoValor);
  };

  const back = AinaCarRentACAR; // Assuming back is the background image

  const [ofertas, setOfertas] = useState([]);
  const isMobileDevice = window.innerWidth <= 500;

  const token = "mi-token-estatico";

  useEffect(() => {
    const fetchOfertas = async () => {
      try {
        const fetchedPopUp = await axios.get(
          `/api/public/ofertas?token=${token}`
        );
        setOfertas(fetchedPopUp.data.reverse());
      } catch (error) {
        console.error("Error al obtener el Pop Up:", error);
      }
    };

    fetchOfertas();
  }, [token]);

  return (
    <>
      <Karve02
        isOpenReservation={isOpenReservation}
        reservationDrawer={reservationDrawer}
      />
      <ContactDialog openDialog={open} handleClose={handleClose} />

      <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
        <Whatsapp />
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          <ResponsiveAppBar
            reservationDrawer={reservationDrawer}
            onCambio={manejarCambio}
          />
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), white), url(${back})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      {t("ofertas_ainacar")}
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      {t("proxima_aventura")}
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>
        {/*<Container sx={{ mt: 15, mb: 8 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <MKTypography gutterBottom variant="h3">
              Próximamente
            </MKTypography>
          </Box>
        </Container>*/}

        <Container sx={{ mt: 8, mb: 12 }}>
          <Grid container spacing={4} sx={{ mt: 5 }}>
            {ofertas.map((oferta, index) => (
              <Grid key={index} item xs={12} md={6}>
                <Box
                  p={0}
                  mx={2}
                  my={2}
                  component="img"
                  src={
                    isMobileDevice ? oferta.url_imagen_movil : oferta.image_url
                  }
                  alt="Example Image"
                  sx={{
                    borderRadius: "10px",
      overflow: "hidden",
                    objectFit: {
                      xs: "contain", // Para vistas pequeñas
                      sm: "contain", // Para vistas medianas en adelante
                    },
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "@media (max-width: 600px)": {
                      height: "auto",
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>

        <MKBox bgColor="info">
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              <Footer pt={6} px={1} content={footerRoutes} />
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default AinaPromo;
